
import { objectFlip } from '@/utilities'
import { PRODUCT_FILTER_TYPES } from '@/constants'
import { mapState, storeToRefs } from 'pinia'
import { useMarketStore } from '~/stores/market'
import { useUiStore } from '@/stores/ui'

export default {
  name: 'MPFilters',
  props: {
    viewMode: {
      type: String,
      default: '',
    },
  },
  setup() {
    const marketStore = useMarketStore()
    const uiStore = useUiStore()
    const { resolution } = storeToRefs(uiStore)
    return {
      marketStore,
      resolution,
    }
  },
  data() {
    return {
      expandedFilters: false,
    }
  },
  computed: {
    ...mapState(useMarketStore, ['tag', 'selfCategory']),
    showToggleAllFilters() {
      return !Object.keys(this.$route.query).includes('q[default_facets]')
    },
    isIOS() {
      if (process.client) {
        const userAgent =
          navigator.userAgent || navigator.vendor || window.opera
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return true
        } else {
          return false
        }
      }
      return false
    },
    showCategories() {
      return (
        this.$route.params.brand ||
        this.$route.params.term ||
        this.$route.params.merchant ||
        this.$route.params.tag
      )
    },
    hasFoundProducts() {
      return this.buckets.length > 0
    },
    filtersDefault() {
      return this.marketStore.localFilters?.default || []
    },
    defaultFilters() {
      // Hide category filter
      return this.filtersDefault.filter(item => item.field !== 'categories.id')
    },
    customFilters() {
      return this?.marketStore.localFilters?.custom || []
    },
    isBrandORMerchantPage() {
      return this.$route.params.brand || this.$route.params.merchant
    },
    lengthCategoriesFilters() {
      const categories = this.marketStore.filters?.default?.find(
        filter => filter?.field === 'categories.id',
      )
      return categories?.buckets?.[0].children.length || []
    },
    isVisibleClearFilter() {
      return (
        !this.$route.params.brand ||
        !this.$route.params.term ||
        this.$route.params.merchant
      )
    },
    hasOnlyOneBoolean() {
      return (
        this.defaultFilters.filter(filter => filter.filter_type === 'boolean')
          ?.length > 1
      )
    },
    buckets() {
      let buckets = []
      this.filtersDefault.forEach(filter => {
        buckets = [...buckets, ...this.getDefaultFilterValue(filter)]
      })
      this.customFilters.forEach(filter => {
        filter.buckets.forEach(bucket => {
          if (bucket.checked) {
            buckets.push({ ...bucket, filterID: filter.id })
          }
        })
      })

      return buckets
    },
  },
  watch: {
    $route: {
      handler() {
        this.initQueries()
      },
      deep: true,
    },
  },
  mounted() {
    this.initQueries()
    this.setVH()
    window.addEventListener('resize', this.setVH)
  },
  beforeDestroy() {
    this.marketStore.productFilters = {}
    window.removeEventListener('resize', this.setVH)
  },
  methods: {
    setVH() {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },
    lastChecked(last) {
      const fil = this.defaultFilters.find(f => f.field == 'manufacturer')
      fil.last = last
    },
    clearFilters() {
      // return if empty filters
      const rout_query = this.$route.query
      if (Object.keys(rout_query).length === 0) {
        return
      }
      if (
        (this.tag.brand || this.tag.master_field) &&
        this.$route.name?.includes('tags-tag')
      ) {
        this.$router.push({
          path: this.localePath({
            name: 'categories-slug',
            params: {
              slug: `${this.selfCategory?.data.id}-${this.selfCategory?.data?.slugged_name}`,
            },
          }),
        })
      } else {
        this.$root.$emit('mp-clear-filters', true)
        this.marketStore.productFilters = {}
        this.marketStore.localFilters.default.map(filter => {
          if (filter.filter_type === 'multi' && filter.buckets)
            filter.buckets.map(bucket => {
              bucket.checked = false
            })
          else if (filter.filter_type === 'boolean') {
            filter.checked = false
          }
        })
        this.marketStore.localFilters.custom.map(filter => {
          if (filter.buckets)
            filter.buckets.map(bucket => {
              bucket.checked = false
            })
        })
      }
    },
    isBooleanField(field) {
      return ['has_taxit_available'].includes(field)
    },
    initQueries() {
      const queries = JSON.parse(JSON.stringify(this.$route.query))
      const _selectedQueries = {}
      Object.keys(queries).map(key => {
        const params =
          typeof queries?.[key] === 'string'
            ? queries?.[key]
            : Array.isArray(queries?.[key])
            ? queries?.[key]?.[0]
            : ''
        if (key !== 'page') {
          const _key = key.replace('q[', '').replace(']', '')
          _selectedQueries[objectFlip(PRODUCT_FILTER_TYPES)[_key]] = params
            ? params?.split(',')
            : params || ''
        }
      })
      // Default filters
      this.filtersDefault.map(filter => {
        if (
          filter.field === 'retail_price' &&
          Object.keys(_selectedQueries).includes('retail_price_lteq') &&
          Object.keys(_selectedQueries).includes('retail_price_gteq')
        ) {
          this.$set(
            filter,
            'selectedMin',
            +_selectedQueries.retail_price_gteq[0],
          )
          this.$set(
            filter,
            'selectedMax',
            +_selectedQueries.retail_price_lteq[0],
          )
        }

        if (filter?.field === 'status') {
          this.$set(filter, 'checked', Boolean(_selectedQueries?.status?.[0]))
        }

        if (
          (filter.field === 'has_discount' &&
            Object.keys(_selectedQueries).includes('has_discount')) ||
          (filter.field === 'has_taxit_available' &&
            Object.keys(_selectedQueries).includes('has_taxit_available')) ||
          (filter.field === 'has_installment' &&
            Object.keys(_selectedQueries).includes('has_installment')) ||
          (filter.field === 'premium_partner' &&
            Object.keys(_selectedQueries).includes('premium_partner'))
        ) {
          this.$set(
            filter,
            'checked',
            Boolean(_selectedQueries[filter.field][0]),
          )
        }

        if (
          Object.keys(_selectedQueries).includes(filter.field) &&
          filter?.buckets
        ) {
          filter?.buckets?.map(bucket => {
            if (
              _selectedQueries[filter.field].includes(bucket.title) ||
              _selectedQueries[filter.field].includes(bucket.uuid)
            ) {
              this.$set(bucket, 'checked', true)
            }
          })
        }
      })

      this.customFilters.map(filter => {
        if (Object.keys(_selectedQueries).includes('facets')) {
          const facets = (_selectedQueries && _selectedQueries.facets) || []
          let parsedFacets = []
          facets?.map(facet => {
            parsedFacets = facet?.split('|')
          })
          const _selectedFacets = parsedFacets.map(pFacet => {
            return {
              key: +pFacet?.split(':')?.[0],
              value: pFacet?.split(':')?.[1],
            }
          })

          _selectedFacets.map(selectedFacet => {
            if (filter.id === selectedFacet.key) {
              if (filter && filter.buckets) {
                filter.buckets.map(bucket => {
                  if (filter?.category_master_field_type === 'range') {
                    if (String(bucket.id) === String(selectedFacet.value)) {
                      bucket.checked = true
                    }
                  } else if (bucket.title === selectedFacet.value) {
                    bucket.checked = true
                  }
                })
              }
            }
          })
        }
      })
    },
    toggleExpand() {
      if (this.resolution === 'sm' || this.resolution === 'md') {
        const sectionFilters = this.$refs.sectionFilters
        sectionFilters.style = `height: ${window.innerHeight - 161}px`
        this.expandedFilters = !this.expandedFilters
        if (this.expandedFilters) {
          document.body.classList.add('overflow-hidden')
        } else {
          document.body.classList.remove('overflow-hidden')
        }
      }
    },

    getDefaultFilterValue(filter) {
      if (filter.field === 'status') {
        if (filter.checked === true)
          return [
            {
              field: filter.field,
              checked: filter.checked,
              title: filter.title,
            },
          ]
      }
      if (filter.field === 'retail_price') {
        const bucket = this.getBucketsPriceFilter(filter)
        if (bucket) {
          return [bucket]
        }
      } else if (
        filter.field === 'merchant_uuid' ||
        filter.field === 'manufacturer' ||
        filter.field === 'seller_id'
      ) {
        const data = []
        filter.buckets.forEach(item => {
          if (item.checked) {
            data.push({
              ...item,
              field: filter.field,
            })
          }
        })
        return data
      } else if (
        filter.field === 'has_discount' ||
        filter.field === 'has_taxit_available' ||
        filter.field === 'has_installment' ||
        filter.field === 'premium_partner'
      ) {
        if (filter.checked === true)
          return [
            {
              field: filter.field,
              checked: filter.checked,
              title: filter.title,
            },
          ]
      }
      return []
    },
    getBucketsPriceFilter(filter) {
      let bucket = null

      const max_pr = this.$route?.query['q[ret_price_max]']
      const select_max_pr = this.$route?.query['q[retail_price_lteq]']

      const min_pr = this.$route?.query['q[ret_price_min]']
      const select_min_pr = this.$route?.query['q[retail_price_gteq]']

      const is_filter_prise = select_max_pr || select_min_pr

      if (
        (is_filter_prise && +max_pr > +select_max_pr) ||
        +min_pr < +select_min_pr
      ) {
        bucket = {
          checked: true,
          title: `${filter.selectedMin} - ${filter.selectedMax} ₼`,
          field: 'retail_price',
        }
      }
      return bucket
    },
    loadAllFilters() {
      let query = { ...this.$route.query }
      query = { ...query, ...{ [`q[default_facets]`]: false } }
      this.$nextTick(() => {
        this.marketStore.productFilters = query
      })
    },
  },
}
